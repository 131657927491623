export const GST_REG =
  /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;

export const PHONE_REGEX = /^\d{6,12}$/;

export const URL_REG1 = /^(https?:\/\/)([\w-]+\.)?(\w+)\.([a-z]{2,63})(\/.*)*$/;

export const PASSWORD_REGEX =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()\-_=+{};:,<.>]).{6,}$/;

export const EMAIL_REGEX =
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

export const WEBSITE_REGEX = /^(https?:\/\/)([a-z0-9-]+\.)+([a-z]+)(.*)$/i;

export const LINKS_REGEX = /^(https?:\/\/)([a-z0-9-]+\.)+([a-z]+)(.*)$/i;

export const WA_NUMBER_REGEX = /^\+[1-9]\d{8,16}$/;

export const BRANCH_LINK_REPLACE = /https:\/\/(.*)\.app\.link(.*)/;

export const FOLDERNAME_SANITIZE = /[&/\\#,+()$~%.'":*?<>{}|]/g;

// ref: https://stackoverflow.com/questions/1976007/what-characters-are-forbidden-in-windows-and-linux-directory-names
export const FILENAME_SANITIZE = /[\\/:*?"<>|]/g;

import { takeLatest, put, call } from "redux-saga/effects";
import {
  GET_BRANCH_WEB_URL,
  GET_ALL_COUNTRIES,
  GET_STATES,
  GET_CITIES,
} from "./saga-actions";
import { getBranchKey } from "views/download/helpers";
import { setBranchLink, setGlobalLoading } from "redux-store/slices/globals";
import { setCountries, setStates, setCities } from "redux-store/slices/globals";
import axios from "axios";
import generateCipher from "utils/helpers/generateCipher";

export const AXIOS = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

async function getBranchUrl(payload) {
  const { title, link, icon, tag, description } = payload;
  const { key, name } = getBranchKey();

  return axios.post("https://api2.branch.io/v1/url", {
    branch_key: key,
    channel: "web",
    campaign: "Web_Share_Link",
    tags: tag || "web_only_link",
    data: {
      $og_title: title,
      $og_description: description || name,
      $og_image_url: icon,
      $desktop_url: link,
      $android_url: link,
      $ios_url: link,
      $web_only: true,
    },
  });
}

function* getBranchUrlGenerator({ payload }) {
  const finalLink = `${window.location.origin || process.env.REACT_APP_HOST}/${
    payload?.link
  }`;

  try {
    yield put(setGlobalLoading(true));
    const response = yield call(getBranchUrl, { ...payload, link: finalLink });
    yield put(setBranchLink(response.data));
  } catch (error) {
    yield put(setBranchLink({ url: finalLink }));
  } finally {
    yield put(setGlobalLoading(false));
  }
}

async function getAllCountries() {
  const cipher = generateCipher();
  return AXIOS.get("/api/validation/countries", { headers: { cipher } });
}

function* getAllCountriesGenerator() {
  try {
    const response = yield call(getAllCountries);
    yield put(setCountries(response.data.data));
  } catch (error) {
    //
  }
}

async function getStates({ countryCode }) {
  const cipher = generateCipher();
  return AXIOS.get(`/api/validation/states`, {
    headers: { cipher },
    params: { countryCode },
  });
}

function* getStatesGenerator({ payload }) {
  try {
    const response = yield call(getStates, payload);
    yield put(setStates(response.data.data));
  } catch (error) {
    //
  }
}

async function getCities({ countryCode, isoCode }) {
  const cipher = generateCipher();
  return AXIOS.get(`/api/validation/cities`, {
    headers: { cipher },
    params: { countryCode, stateCode: isoCode },
  });
}

function* getCitiesGenerator({ payload }) {
  try {
    const response = yield call(getCities, payload);
    yield put(setCities(response.data.data));
  } catch (error) {
    //
  }
}

export function* globalsSaga() {
  yield takeLatest(GET_BRANCH_WEB_URL, getBranchUrlGenerator);
  yield takeLatest(GET_ALL_COUNTRIES, getAllCountriesGenerator);
  yield takeLatest(GET_STATES, getStatesGenerator);
  yield takeLatest(GET_CITIES, getCitiesGenerator);
}

import React from "react";
import { Modal } from "react-bootstrap";
import DefaultButton from "../button/DefaultButton";
import MobileNoInput from "views/auth/components/MobileNoInput";
import { useSelector } from "react-redux";

const UserDetailsForm = ({
  show,
  setUser,
  user,
  onSubmit,
  onCancel,
  err,
  type,
}) => {
  const { country } = useSelector((state) => state.globals);

  React.useEffect(() => {
    if (country) setUser({ ...user, cc: country.dial_code });
  }, [country]);

  return (
    <Modal centered show={show} onHide={onCancel}>
      <Modal.Header className="mx-auto font-bold border-0" closeButton>
        <Modal.Title className="get-connected-text">Get Connected</Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-4 pt-0">
        <form onSubmit={onSubmit} className="user-details-form">
          <input
            type="text"
            value={user.name}
            onChange={(e) => setUser({ ...user, name: e.target.value })}
            placeholder="Enter Full Name"
            required
          />
          <MobileNoInput
            name={"loginCredential"}
            type="number"
            placeholder={"Phone Number"}
            val={user.phone}
            countryCode={user.cc}
            classes="justify-content-start align-items-center contact-us-page"
            onChange={(e) => {
              setUser({ ...user, phone: e.target.value });
            }}
            setCountryCode={(e) => {
              setUser({ ...user, cc: e });
            }}
            allowOnlyPhone
          />
          <input
            type="email"
            value={user.email}
            onChange={(e) => setUser({ ...user, email: e.target.value })}
            placeholder={
              type === "USER" ? "Enter Email" : "Enter Company Email"
            }
            required
          />
          {err && <p className="input_fields-error mb-2">{err}</p>}
          <DefaultButton title="Continue" type="submit" />
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default UserDetailsForm;

import React from "react";
import { Col, Container, Row, Form } from "react-bootstrap";
import MobileNoInput from "views/auth/components/MobileNoInput";
import css from "../index.module.css";
import GradButton from "views/components/button/GradButton";
import sendSlackMessage from "utils/helpers/sendSlackMessage";
import { toast } from "react-toastify";
import { useSearchParams } from "react-router-dom";
import { getLocalStorageUser } from "utils/helpers/localstorage";
import ConfirmationModal from "views/components/modals/ConfirmationModal";

const GetInTouch = ({
  slackChannel,
  countryCode,
  sendTrackingEvent = () => {},
}) => {
  const formRef = React.useRef({
    name: null,
    companyName: null,
    email: null,
    phone: null,
    query: null,
    message: null,
  });
  const [params] = useSearchParams();
  const [showConfirmation, setShowConfirmation] = React.useState(false);

  const lsuser = getLocalStorageUser();

  const user = {
    name: lsuser?.name || params.get("name"),
    lastName: lsuser?.lastName || params.get("lastName"),
    email: lsuser?.email || params.get("email"),
    phoneNumber: lsuser?.phoneNumber || params.get("phoneNumber"),
    countryCode:
      lsuser?.countryCode ||
      (params.get("countryCode")
        ? "+" + params.get("countryCode")
        : lsuser?.secondaryPhoneCountryCode) ||
      countryCode,
    companyName: params.get("companyName"),
    query: params.get("query"),
    message: params.has("message")
      ? decodeURIComponent(params.get("message"))
      : "",
  };

  const [phone, setPhone] = React.useState({
    phone: user.phoneNumber || "",
    cc: user.countryCode || "",
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    if (phone.phone.length < 6 || phone.phone.length > 14)
      return toast.error("Please enter a valid phone number");

    const paramsCopy = new URLSearchParams(params.toString());

    paramsCopy.delete("name");
    paramsCopy.delete("lastName");
    paramsCopy.delete("email");
    paramsCopy.delete("phoneNumber");
    paramsCopy.delete("countryCode");
    paramsCopy.delete("companyName");
    paramsCopy.delete("query");
    paramsCopy.delete("message");

    let utmParams = "";
    paramsCopy.forEach((value, key) => {
      utmParams += `\n${key}: ${value}`;
    });

    let msg = `*Name:* ${formRef.current.name.value}\n*Company Name:* ${formRef.current.companyName.value}\n*Email:* ${formRef.current.email.value}\n*Phone Number:* ${phone.cc} ${phone.phone}\n*Query type:* ${formRef.current.query.value}\n*Message:* ${formRef.current.message.value}`;

    if (utmParams) {
      msg += `\n\n*Tracking Parameters:* ${utmParams}`;
    }

    sendSlackMessage(msg, slackChannel);

    formRef.current.name.value = "";
    formRef.current.companyName.value = "";
    formRef.current.email.value = "";
    formRef.current.query.value = "";
    formRef.current.message.value = "";
    setPhone({ cc: user.countryCode, phone: "" });

    setShowConfirmation(true);

    sendTrackingEvent();
  };

  return (
    <Container fluid id="contact" className={css.contact}>
      <Container className="py-4 py-md-5">
        <Row>
          <Col md={5}>
            <h2 className="font-24 font-bold">Get in Touch</h2>
            <img
              src="/assets/images/landing/yellow-divider.png"
              alt="Yellow-divider"
              className="mb-4"
            />
            <p className="font-base font-15">
              We&apos;re here to help. Book a demo or get technical support,
              reach out to us for anything!
            </p>
            <Form onSubmit={handleSubmit} className={css.contactForm}>
              <Form.Group className="mb-2">
                <Form.Control
                  type="text"
                  placeholder="Full Name"
                  defaultValue={`${user.name || ""} ${
                    user.lastName || ""
                  }`.trim()}
                  onChange={(e) => {
                    formRef.current.name.value = e.target.value;
                  }}
                  ref={(ref) => (formRef.current.name = ref)}
                  required
                  minLength={3}
                />
              </Form.Group>
              <Form.Group className="mb-2">
                <Form.Control
                  type="text"
                  placeholder="Business Name"
                  defaultValue={user.companyName}
                  onChange={(e) => {
                    formRef.current.companyName.value = e.target.value;
                  }}
                  ref={(ref) => (formRef.current.companyName = ref)}
                  minLength={3}
                />
              </Form.Group>
              <Form.Group className="mb-2">
                <Form.Control
                  type="email"
                  placeholder="Email Id"
                  defaultValue={user.email}
                  onChange={(e) => {
                    formRef.current.email.value = e.target.value;
                  }}
                  ref={(ref) => (formRef.current.email = ref)}
                  required
                  minLength={8}
                />
              </Form.Group>
              <Form.Group className="mb-2">
                <MobileNoInput
                  name="phone"
                  type="number"
                  placeholder="Phone Number"
                  val={phone.phone}
                  countryCode={phone.cc}
                  classes="justify-content-start align-items-center contact-us-page"
                  onChange={(e) => {
                    setPhone({ ...phone, phone: e.target.value });
                  }}
                  setCountryCode={(e) => {
                    setPhone({ ...phone, cc: e });
                  }}
                  allowOnlyPhone
                />
              </Form.Group>
              <Form.Group className="mb-2">
                <Form.Select
                  onChange={(e) => {
                    formRef.current.query.value = e.target.value;
                  }}
                  ref={(ref) => (formRef.current.query = ref)}
                  defaultValue={user.query || ""}
                  required
                >
                  <option value="" disabled>
                    Select Query Type
                  </option>
                  <option value="General enquiry">General enquiry</option>
                  <option value="Book a demo">Book a demo</option>
                  <option value="Technical support">Technical support</option>
                  <option value="Sales and pricing">Sales and pricing</option>
                </Form.Select>
              </Form.Group>
              <Form.Group className="mb-2">
                <Form.Control
                  as="textarea"
                  className="col-md-12 pt-2"
                  name=""
                  id=""
                  cols="20"
                  rows="8"
                  placeholder="Write a message"
                  defaultValue={user.message}
                  onChange={(e) => {
                    formRef.current.message.value = e.target.value;
                  }}
                  ref={(ref) => (formRef.current.message = ref)}
                  minLength={25}
                  maxLength={500}
                  required
                />
              </Form.Group>
              <GradButton
                title="Submit"
                className="font-bold px-5 mt-3"
                variant="md"
              />
            </Form>
          </Col>
          <Col
            md={7}
            className="ps-md-4 pt-5 pt-md-0 d-flex flex-column align-items-center justify-content-center"
          >
            <h3 className="font-bold font-18 color-secondary text-center">
              Want to learn more about Kwikpic?
            </h3>
            <p className="color-gray font-base font-15">
              Watch our Tutorial Videos!
            </p>
            <a
              href="https://www.youtube.com/playlist?list=PLO8szijcLbfUq-K2d-f3aGggauF1w-T-p"
              target="_blank"
              rel="noopener noreferrer"
              className="mb-3"
            >
              <img
                src="/assets/images/country-landing/tutorial.png"
                alt="youtube tutorial"
                className="w-100 rounded-3"
              />
            </a>
          </Col>
        </Row>
      </Container>

      {showConfirmation && (
        <ConfirmationModal
          show
          confirmText=""
          cancelText="Close"
          onCancel={() => setShowConfirmation(false)}
          buttonRounded={false}
          title="Thank you for contacting us!"
          message="We have received your message and will get back to you as soon as possible."
        />
      )}
    </Container>
  );
};

export default GetInTouch;

import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import ProgressCard from "../progressBar/ProgressBar";
import ButtonInput from "../input/ButtonInput";
import { AXIOS } from "utils/setup/axios";
import { toast } from "react-toastify";
import Spinner from "../loader/Spinner";
import { useSearchParams } from "react-router-dom";
import SecondarySmallBtn from "../button/SecondarySmallBtn";
// import usePreventUnsavedNavigation from "hooks/usePreventUnsavedNavigation";
import useUploadStatus from "hooks/useUploadingStatus";
import { getLocalStorageUser, lsProxy } from "utils/helpers/localstorage";
import { useSelector, useDispatch } from "react-redux";
import SubscriptionModal from "views/subscription";
import StorageFullModal from "./StorageFullModal";
import { CANCEL_LINK_UPLOAD } from "redux-store/sagas/saga-actions";
import PrimarySmallBtn from "../button/PrimarySmallBtn";
import ConfirmationModal from "./ConfirmationModal";
import Backdrop from "./components/Backdrop";
import { SocketContext } from "context/socket";

export const MODAL_TYPES = {
  SUBSCRIPTION: "SUBSCRIPTION",
  STORAGE: "STORAGE",
  STORAGE2X: "STORAGE2X",
  STOP_UPLOAD: "STOP_UPLOAD",
  NONE: "",
};

const UploadFromLink = (props) => {
  const socket = React.useContext(SocketContext);
  const [loading, setLoading] = React.useState(false);
  const [status, setStatus] = React.useState({
    type: LINK_STATUS.NONE,
    data: {},
  });
  const { isParallelyUploading } = useUploadStatus();
  const { highRes } = getLocalStorageUser();
  const [modal, setModal] = React.useState(MODAL_TYPES.NONE);
  const dispatch = useDispatch();

  // const showError =
  //   status.type === LINK_STATUS.PREPARING ||
  //   status.type === LINK_STATUS.UPLOADING;
  const [params] = useSearchParams();

  // usePreventUnsavedNavigation({
  //   message: "Photos will still process in background. Do you want to leave?",
  //   block: showError,
  // });
  const { userAnalytics, saveLoader } = useSelector((state) => state.settings);
  const { photoUploads, highResPhotoUploads, uploadLimit, totalUtilisation } =
    userAnalytics || {};

  const oneXCondition = totalUtilisation > uploadLimit;

  const twoXCondition =
    photoUploads + highResPhotoUploads * 2.5 > uploadLimit * 2;

  useEffect(() => {
    socket?.on("linkUploadInfo", (data) => {
      if (data?.status === 206) {
        lsProxy.setItem("isUploading", true);
        lsProxy.setItem("uploadingFrom", "Link");
        const imageCounts = data?.message?.split("/");
        setStatus({
          type: LINK_STATUS.UPLOADING,
          data: {
            ...status.data,
            totalImages: imageCounts?.[1] || 0,
            uploadedImages: imageCounts?.[0] || 0,
          },
        });
      } else if (data?.status === 201) {
        setStatus({
          type: LINK_STATUS.PREPARING,
          data,
        });
      } else if (data?.status === 406) {
        lsProxy.removeItem("isUploading");
        lsProxy.removeItem("uploadingFrom");
        setStatus({
          type: LINK_STATUS.ERROR,
          data,
        });
      } else if (data?.status === 200) {
        const imageCounts = data?.message?.split("/");
        lsProxy.removeItem("isUploading");
        lsProxy.removeItem("uploadingFrom");
        setStatus({
          type: LINK_STATUS.COMPLETED,
          data: {
            ...status.data,
            totalImages: imageCounts?.[0] || 0,
            uploadedImages: imageCounts?.[0] || 0,
          },
        });
      } else {
        setStatus({
          type: LINK_STATUS.NONE,
          data: {},
        });
      }
    });
  }, [socket]);

  const uploadLink = async (link) => {
    const isAlreadyuploading = await isParallelyUploading();
    if (isAlreadyuploading) {
      toast.error("One upload is already in progress.");
      return;
    }
    lsProxy.setItem("isUploading", true);
    lsProxy.setItem("uploadingFrom", "Link");
    setLoading(true);

    try {
      const response = await AXIOS.patch(
        `/api/app/group/upload-image-link/${params.get("groupId")}`,
        {
          link,
          highRes,
        }
      );
      if (response.status === 200) {
        setLoading(false);
        setStatus({
          type: LINK_STATUS.PREPARING,
          data: {},
        });

        // hopefully socket will be connected by the time this function is called.
        // if not, we'll modify provider to return a promise, which we'll await here first
        socket?.on("linkUploadInfo", (data) => {
          switch (data.status) {
            case 201:
              setStatus({
                type: LINK_STATUS.PREPARING,
                data,
              });
              break;
            case 206: {
              const imageCounts = data?.message?.split("/");
              setStatus({
                type: LINK_STATUS.UPLOADING,
                data: {
                  ...status.data,
                  totalImages: imageCounts?.[1] || 0,
                  uploadedImages: imageCounts?.[0] || 0,
                },
              });
              break;
            }
            case 200: {
              const imageCounts = data?.message?.split("/");
              lsProxy.removeItem("isUploading");
              lsProxy.removeItem("uploadingFrom");
              setStatus({
                type: LINK_STATUS.COMPLETED,
                data: {
                  ...status.data,
                  totalImages: imageCounts?.[0] || 0,
                  uploadedImages: imageCounts?.[0] || 0,
                },
              });
              break;
            }
            case 406: {
              lsProxy.removeItem("isUploading");
              lsProxy.removeItem("uploadingFrom");
              setStatus({
                type: LINK_STATUS.ERROR,
                data,
              });

              if (data?.message?.includes("exceeded")) {
                if (data?.message?.includes("Upload limit of"))
                  setModal(MODAL_TYPES.STORAGE);
                else if (data?.message.includes("Yearly upload limit"))
                  setModal(MODAL_TYPES.STORAGE2X);
              }
            }
          }
        });
      }
    } catch (err) {
      setLoading(false);
      const { response: { data } = {} } = err;
      const { message } = data || {};

      if (data.status === 429) {
        lsProxy.removeItem("isUploading");
        lsProxy.removeItem("uploadingFrom");
      }
      toast.error(message || "Invalid link", {
        autoClose: 10000,
      });
    }
    setLoading(false);
  };

  const validateLink = async (link) => {
    if (oneXCondition) return setModal(MODAL_TYPES.STORAGE);
    else if (twoXCondition) return setModal(MODAL_TYPES.STORAGE2X);

    setLoading(true);
    const formData = new FormData();
    formData.append("link", link);
    try {
      const response = await AXIOS.post("/api/validate_drive_link", formData);
      const { data } = response || {};
      if (data?.status === 200) {
        toast.success("Link Verified");
        setStatus({
          type: LINK_STATUS.VALID,
          data: {
            link,
          },
        });
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      const { response: { data } = {} } = err;
      const { message, status } = data || {};

      if (status === 400) return toast.error("Could not connect to the server");

      toast.error(message, {
        bodyClassName: "font-15",
      });
    }
  };

  const renderContent = (_status) => {
    switch (_status) {
      case LINK_STATUS.NONE:
        return (
          <>
            <p className="bold-font font-15">
              Insert Google Drive, OneDrive or Dropbox Link
            </p>
            <ButtonInput
              onClick={(value) => validateLink(value)}
              placeholder="Link"
              imgSrc="/assets/icons/link.svg"
            />
          </>
        );

      case LINK_STATUS.VALID:
        return (
          <>
            <SecondarySmallBtn
              title="Start Upload"
              onClick={() => uploadLink(status.data?.link)}
            />
            <p className="mt-2 font-bold font-15 bright-blue-text">
              You cannot pause the upload once it has started.
            </p>
          </>
        );

      case LINK_STATUS.PREPARING:
        return (
          <>
            <p className="font-bold font-15 bright-blue-text">Preparing...</p>
            <div>
              <PrimarySmallBtn
                title="Stop Upload"
                onClick={() => setModal(MODAL_TYPES.STOP_UPLOAD)}
                className="font-bold mx-auto"
              />
            </div>
          </>
        );

      case LINK_STATUS.UPLOADING:
        return (
          <p className="font-bold font-15 bright-blue-text text-center">
            Uploading...
            <br />
            <br />
            <p className="m-0 font-13">
              Note: You may close this window and come back later to check the
              status.
            </p>
            <div>
              <PrimarySmallBtn
                title="Stop Upload"
                onClick={() => setModal(MODAL_TYPES.STOP_UPLOAD)}
                className="font-bold mx-auto mt-4"
              />
            </div>
          </p>
        );

      case LINK_STATUS.ERROR:
        return (
          <p className="font-bold font-15 bright-blue-text warning-error">
            {`Error! ${status.data.message}`}
            <img
              className="warning-icon"
              src="/assets/icons/warning.png"
              alt=""
            />
          </p>
        );

      case LINK_STATUS.COMPLETED:
        return (
          <>
            <div className="d-flex align-items-top">
              <p className="font-bold font-15 bright-blue-text m-0 me-2">
                Uploaded
              </p>
              <img
                src="/assets/images/icons/tick-green.png"
                className="hw-20px"
                alt="green-tick"
              />
            </div>
            <span className="font-12 gray-text base-font">
              All photos have been processed!
            </span>
          </>
        );
    }
  };

  const progressText =
    (status?.data?.uploadedImages || 0) +
    "/" +
    (status?.data?.totalImages || 0) +
    " Photos";

  const progressPercentage =
    ((status?.data?.uploadedImages || 0) / (status?.data?.totalImages || 0)) *
    100;

  const isCompleted = status?.type === LINK_STATUS.COMPLETED;

  return (
    <Modal
      centered
      show={props.show}
      onHide={() => {
        if (status.type === LINK_STATUS.PREPARING) {
          toast.error("Please wait for the upload to start.");
        } else {
          setStatus({
            type: LINK_STATUS.NONE,
            data: {},
          });
          props.handleClose();
        }
      }}
      animation={false}
      className="uploadFromLink"
    >
      <Spinner loading={loading || saveLoader} />
      <Modal.Header
        closeButton={true}
        className="justify-content-center border-0 pb-0"
      >
        <Modal.Title>
          <p className="font-bold font-24 semi-black-text mb-0">
            Upload Photos from Link
          </p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {highRes && (
          <p className="font-13 color-danger font-bold mb-0 me-3 text-end">
            <img
              src="/assets/images/icons/privacy-security/caution.png"
              alt="info plain"
              className="info-icon"
            />
            &nbsp;High Resolution mode is ON
            <br />
            <span className="text-decoration-underline">
              1 High-Res Upload = 2.5 Photos
            </span>
          </p>
        )}
        <div className="row align-items-center mb-4">
          <div className="col-md-7 d-flex">
            <img src="/assets/icons/jpg-icon.svg" alt="jpg-icon" />
            <ProgressCard
              leftText={progressText}
              rightText={isCompleted ? "Completed" : ""}
              progressPercentage={progressPercentage}
              thumb={true}
            />
          </div>
          <div className="col-md-5">
            <p className="mb-0 mt-4 font-11 font-base semi-black-text">
              Sub-folders inside the link will be created automatically to form
              entire event directory
            </p>
          </div>
        </div>

        <div className="d-flex">
          <div className="dropableCard">{renderContent(status.type)}</div>
        </div>
      </Modal.Body>

      {modal === MODAL_TYPES.STOP_UPLOAD && (
        <>
          <Backdrop onClick={() => setModal(MODAL_TYPES.NONE)} />
          <ConfirmationModal
            show={modal === MODAL_TYPES.STOP_UPLOAD}
            title="Are you sure you want to stop the upload?"
            cancelText="No"
            confirmText="Yes"
            onCancel={() => setModal(MODAL_TYPES.NONE)}
            onConfirm={() => {
              dispatch({
                type: CANCEL_LINK_UPLOAD,
                payload: {
                  groupId: params.get("groupId"),
                },
              });
              setModal(MODAL_TYPES.NONE);
            }}
          />
        </>
      )}
      <StorageFullModal
        show={modal === MODAL_TYPES.STORAGE || modal === MODAL_TYPES.STORAGE2X}
        onHide={() => setModal(MODAL_TYPES.NONE)}
        onConfirm={() => setModal(MODAL_TYPES.SUBSCRIPTION)}
        type="photos"
        exceed={modal === MODAL_TYPES.STORAGE ? "1x" : "2x"}
      />
      <SubscriptionModal
        title="UPGRADE YOUR PLAN"
        isOpen={modal === MODAL_TYPES.SUBSCRIPTION}
        onClose={() => setModal(MODAL_TYPES.NONE)}
      />
    </Modal>
  );
};

export default UploadFromLink;

const LINK_STATUS = {
  NONE: "NONE",
  VALID: "VALID",
  PREPARING: "PREPARING",
  UPLOADING: "UPLOADING",
  COMPLETED: "COMPLETED",
  ERROR: "ERROR",
};
